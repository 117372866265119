import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ScreenDisplayUi from "../views/ScreenDisplayUi.vue";
import ScreenDisplayUiSub from "../views/ScreenDisplayUi-sub.vue";
import Vue2 from "../views/Vue2.vue";
import Vue2Sub from "../views/Vue2-sub.vue";
import Multiple from "../views/Multiple.vue";
const basename = process.env.NODE_ENV === "production" ? "/main-vue/" : "";

Vue.use(VueRouter);

/* Layout */
import Layout from '@/layout'

// 公共路由
export const constantRoutes = [
    // {
    //   path: "/home",
    //   name: "home",
    //   component: Home,
    // },
    // {
    //     path: "/screenDisplayUi",
    //     name: "screenDisplayUi",
    //     component: ScreenDisplayUi,
    // },
    // {
    //     path: "/ScreenDisplayUi-sub/:path",
    //     name: "ScreenDisplayUi-sub",
    //     component: ScreenDisplayUiSub,
    // },

    // {
    //     path: "/all",
    //     name: "all",
    //     component: Multiple,
    // },
    // {
    //   path: "/",
    //   redirect: "/home",
    // },
    // 分界
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: () => import('@/views/redirect')
            }
        ]
    },
    {
        path: '/monitoringLargeScreen',
        component: () => import('@/views/largeScreen/index'),
        hidden: true,
        meta: { title: '监控大屏', icon: 'dashboard', affix: true , target: '_blank'},
    },
    {
        path: '/login',
        component: () => import('@/views/login'),
        hidden: true
    },
    {
        path: '/register',
        component: () => import('@/views/register'),
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/error/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/error/401'),
        hidden: true
    },

    {
        path: '',
        component: Layout,
        redirect: 'index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/Home'),
                name: 'Index',
                meta: { title: '首页', icon: 'dashboard', affix: true }
            },
            // {
            //   path: "/yuToole",
            //   name: "vue2",
            //   component: Vue2,
            // },
            // {
            //   path: "/yuToole/:path",
            //   name: "yuToole",
            //   component: Vue2Sub,
            // },
        ]
    },
    {
       path: '/company-cash',
       component: () => import('@/views/largeScreen/companyCash'),
    },
    {
       path: '/operations-manager',
       component: () => import('@/views/largeScreen/operationsManager'),
    },
    {
       path: '/service-execution',
       component: () => import('@/views/largeScreen/serviceExecution'),
    },
    {
       path: '/fleet-signage',
       component: () => import('@/views/largeScreen/fleetSignage'),
    },
    {
        path: '/system',
        component: Layout,
        redirect: 'noRedirect',
        hidden: true,
        children: [
            {
                path: 'profile',
                component: () => import('@/views/system/user/profile/index'),
                name: 'Profile',
                meta: { title: '个人中心', icon: 'user'},
                hidden: true,
            }
        ]
    }
];

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [
    // {
    //   path: '/system/user-auth',
    //   component: Layout,
    //   hidden: true,
    //   permissions: ['system:user:edit'],
    //   children: [
    //     {
    //       path: 'role/:userId(\\d+)',
    //       component: () => import('@/views/system/user/authRole'),
    //       name: 'AuthRole',
    //       meta: { title: '分配角色', activeMenu: '/system/user' }
    //     }
    //   ]
    // },
    // {
    //   path: '/system/role-auth',
    //   component: Layout,
    //   hidden: true,
    //   permissions: ['system:role:edit'],
    //   children: [
    //     {
    //       path: 'user/:roleId(\\d+)',
    //       component: () => import('@/views/system/role/authUser'),
    //       name: 'AuthUser',
    //       meta: { title: '分配用户', activeMenu: '/system/role' }
    //     }
    //   ]
    // },
    // {
    //   path: '/system/dict-data',
    //   component: Layout,
    //   hidden: true,
    //   permissions: ['system:dict:list'],
    //   children: [
    //     {
    //       path: 'index/:dictId(\\d+)',
    //       component: () => import('@/views/system/dict/data'),
    //       name: 'Data',
    //       meta: { title: '字典数据', activeMenu: '/system/dict' }
    //     }
    //   ]
    // },
    // {
    //   path: '/monitor/job-log',
    //   component: Layout,
    //   hidden: true,
    //   permissions: ['monitor:job:list'],
    //   children: [
    //     {
    //       path: 'index/:jobId(\\d+)',
    //       component: () => import('@/views/monitor/job/log'),
    //       name: 'JobLog',
    //       meta: { title: '调度日志', activeMenu: '/monitor/job' }
    //     }
    //   ]
    // },
    // {
    //   path: '/tool/gen-edit',
    //   component: Layout,
    //   hidden: true,
    //   permissions: ['tool:gen:edit'],
    //   children: [
    //     {
    //       path: 'index/:tableId(\\d+)',
    //       component: () => import('@/views/tool/gen/editTable'),
    //       name: 'GenEdit',
    //       meta: { title: '修改生成配置', activeMenu: '/tool/gen' }
    //     }
    //   ]
    // }
]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(err => err)
}
console.log("要打印的路由数据：",constantRoutes)
const router = new VueRouter({
    mode: "hash",
    scrollBehavior: () => ({ y: 0 }),
    base: basename,
    routes: constantRoutes
    // constantRoutes,
});

export default router;
